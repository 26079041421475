"use client";
import React from "react";
import { AspectRatio } from "~/components/ui/aspect-ratio";
import Image from "next/image";
import { Button } from "~/components/ui/button";
import { ArrowLongRight } from "~/components/icons";
import { LinkTrackers } from "~/components/reusables/link-trackers";
import { usePathname } from "next/navigation";
import StrapiBlocksRenderer from "~/components/reusables/strapi-blocks-renderer";

export function BannerClient({ banner, btn, blogs }) {
  const pathname = usePathname();
  const showBanner = pathname == "/"  || pathname == "/the-internet-of-agents";
  const iconURL = banner.ui[0].icon.data.attributes.url;
  const icon = banner.ui[0].icon.data.attributes;

  return (
    <>
      {showBanner && (
        <div className="bg-gradient-to-r from-[#00BCEB] to-[#1E5BB3] text-white ">
          <LinkTrackers
            segmentMsg="Banner Link Clicked"
            segmentOpt={{
              link_label: "Register today",
              location: "banner",
              resource_interaction: "visit the internet of agents event page",
            }}
            href={btn.url}
            target="_blank"
            aria-label="Learn more about Panopica"
            className="lg:font-btn group flex w-full items-center gap-1 py-5  text-xs md:py-6"
          >
            <div className="container flex min-h-14 items-center justify-between gap-3">
              <div className="flex items-center gap-3">
                <div className="self-start lg:self-center">
                  {icon ? (
                    <div className="mt-1 h-full w-20 md:mr-6 md:w-24 lg:mt-0 lg:w-[120px]">
                      <AspectRatio ratio={icon.width / icon.height}>
                        <Image
                          src={iconURL}
                          alt="logo"
                          width={icon.width}
                          height={icon.height}
                          unoptimized
                        ></Image>
                      </AspectRatio>
                    </div>
                  ) : (
                    <div className="mt-2 h-[42px] w-[39px] md:mr-6">
                      <AspectRatio ratio={39 / 42}>
                        <Image
                          src={
                            "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/panoptica-logo-reversed.svg"
                          }
                          alt="Panoptica logo"
                          width={39}
                          height={42}
                          unoptimized
                        ></Image>
                      </AspectRatio>
                    </div>
                  )}
                </div>
                {banner.description && (
                  <StrapiBlocksRenderer content={banner.description} />
                )}
              </div>

              <Button
                variant="secondary"
                className="align-end hidden px-0 font-medium text-[#2979C7] group-hover:bg-white group-hover:text-slate-900 md:px-3 lg:flex"
              >
                {btn.cta ? (
                  <p className="font-h6">{btn.cta}</p>
                ) : (
                  <div className="ml-1">
                    <ArrowLongRight size={35} />
                  </div>
                )}
              </Button>
              <div className="ml-1 lg:hidden">
                <ArrowLongRight size={35} />
              </div>
            </div>
          </LinkTrackers>
        </div>
      )}
    </>
  );
}
